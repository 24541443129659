<template>
    <div class="apartment-section">
        <div class="image-container project-svg" v-html="projectImage"></div>
        <div class="header-container">
            <h2 class="title luxury">{{ activeProject?.title }} {{ $fn.tr('apartments') }}</h2>
        </div>
        <div class="apartments-list">
            <Apartment v-for="item in projects"
                       :key="item.id"
                       :item="item"/>
        </div>
    </div>
</template>

<script setup>
import Apartment from '../../ApartmentView/parts/Apartment'
import {computed, inject} from "vue";
import {useMenuStore} from "../../../../store/pinia/menu";
import {useProjects} from "../../../../store/pinia/projects";

const props = defineProps({
    propData: {
        type: Object,
        required: false
    },
});
let projectStore = useProjects()

let activeProject = computed(() => projectStore.activeProject)
let store = useMenuStore()
let projectImage = computed(() => activeProject.value?.render2?.tabs?.[0]?.render_svg)

const {
    apartmentTypes,
} = inject('project')

let typeData = computed(() => props.propData || apartmentTypes)

let projects = computed(() => {
    return typeData.value?.map((item, index) => ({
        ...item,
        changeUrlById: true,
        mobile: item?.images?.[0]?.devices?.mobile,
        desktop: item?.images?.[0]?.devices?.desktop,
        tablet: item?.images?.[0]?.devices?.tablet,
        mobile1: item?.images2?.[0]?.devices?.mobile,
        desktop1: item?.images2?.[0]?.devices?.desktop,
        tablet1: item?.images2?.[0]?.devices?.tablet,
        views: [
            {
                title: item.view?.view_1_text,
                icon: item.view?.view_1_icon
            },
            {
                title: item.view?.view_2_text,
                icon: item.view?.view_2_icon
            },
        ]
    }))
})
</script>

<style lang="scss" scoped>
.apartment-section {
    width: 100%;
    color: $primaryNavy;

    .image-container:deep {
        width: 326px;
        margin: 0 auto;
        display: none;
        @media (max-width: 1023px) {
            display: block;
        }

        svg {
            width: 100%;

            path, polygon {
                fill: transparent;
                transition: all .45s ease-out;

                &.active {
                    fill: $burgundy;
                }
            }
        }
    }

    .header-container {
        padding-bottom: 32px;
        border-bottom: 2px solid rgba(191, 191, 191, 0.2);

        .title {
            font-weight: 400;
            font-size: 55px;
            line-height: 52px;
            text-transform: uppercase;
            margin-top: 10px;
            @media (max-width: 767px) {
                font-size: 34px;
            }
            @media (max-width: 400px) {
                font-size: 32px;
            }
        }
    }

    .apartments-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 40px 0;
        @media (max-width: 767px) {
            flex-direction: column;
            padding: 23px 0;
        }

        .apartment {
            width: calc(50% - 10px);
            margin-top: 0;
            margin-bottom: 20px;
            @media (max-width: 767px) {
                width: 100%;
            }
        }
    }
}
</style>
<style lang="scss">
body {
    &.is-dark {
        .apartment-section {
            color: white;
        }
    }
}
</style>
