<template>
    <div class="apartment-search">
        <ApartmentFilterSection
            @change="filterChanged=true"
            data-scroll data-scroll-sticky
            data-scroll-target="#pinSection"
            :projects="projects"/>
        <ApartmentFilterMobile :projects="projects"/>
        <div class="right-section">
            <ApartmentSection
                :prop-data="mainTypes"
                v-show="isQueryParameters"/>
            <ApartmentSearchResult v-show="!isQueryParameters"/>
        </div>
        <div class="pin-section"
             :class="{wider:isPinSectionWider}"
             id="pinSection"></div>
        <Teleport to="body">
            <transition name="slide">
                <ApartmentFavoriteList v-if="showFavoriteList"/>
            </transition>
        </Teleport>
    </div>
</template>

<script setup>
import ApartmentSearchResult from './Parts/ApartmentSearchResult'
import ApartmentFilterSection from './Parts/ApartmentFilterSection'
import ApartmentFilterMobile from './Parts/ApartmentFilterMobile'
import ApartmentSection from './Parts/ApartmentSection'
import ApartmentFavoriteList from './Parts/ApartmentFavoriteList'
import func from "../../../helpers/func";
import {computed, nextTick, onMounted, onUpdated, provide, ref} from "vue";
import {useRoute} from 'vue-router'
import {useProjects} from "../../../store/pinia/projects";
import {useMenuStore} from "../../../store/pinia/menu";
import {UseFavoriteFlats} from "../../../store/pinia/FavoriteProjects";
import _isEmpty from 'lodash/isEmpty'

const props = defineProps({
    contentData: {
        type: Object,
    },
});
let projectStore = useProjects()
const route = useRoute()
const menuStore = useMenuStore()
const favoriteStore = UseFavoriteFlats()

let activeProjectSlug = route.params?.path2
let filterChanged = ref(false)
let projects = ref([])
let blocks = ref([])
let floors = ref([])
let apartmentTypes = ref([])
let activeProject = computed(() => projects.value?.find(item => item.slug === activeProjectSlug) || {})
let typeTaxonomy = computed(() => menuStore?.indx?.terms?.category?.filter(item => item.show_in_filter?.value === '1'))
let showFavoriteList = computed(() => favoriteStore.showFavoriteList)
let isQueryParameters = computed(() => {
    return filterChanged.value ? false : _isEmpty(route?.query)
})

let mainTypes = computed(() => props.contentData.data?.list?.map((item, index) => ({
    ...item,
    mobile: item?.images?.[0]?.devices?.mobile,
    desktop: item?.images?.[0]?.devices?.desktop,
    tablet: item?.images?.[0]?.devices?.tablet,
    mobile1: item?.images2?.[0]?.devices?.mobile,
    desktop1: item?.images2?.[0]?.devices?.desktop,
    tablet1: item?.images2?.[0]?.devices?.tablet,
    views: [
        {
            title: item.view?.view_1_text,
            icon: item.view?.view_1_icon
        },
        {
            title: item.view?.view_2_text,
            icon: item.view?.view_2_icon
        },
    ]
})))

const getProjects = async () => {
    let {data} = await func.postData('dev/getProjects')

    projects.value = data?.map(item => ({
        ...item,
        value: item.id,
        label: item.title
    }))?.filter(item => item.page_default !== 1) || []

    //set active project
    projects.value?.find(item => item.slug === activeProjectSlug)
    setActiveProject(projects.value?.find(item => item.slug === activeProjectSlug))
}
const getTypes = async () => {
    let {data} = await func.postData('dev/getTypes', {
        projectIds: [activeProject.value.id],
        pageCount: 100
    })

    apartmentTypes.value = data
}
const getBlocks = async () => {
    let {data} = await func.postData('dev/getBlocks', {projectId: activeProject.value.id})

    blocks.value = data
}
const getFloors = async () => {
    let {data} = await func.postData('dev/getFloors', {blockId: blocks.value.map(item => item.id)})

    floors.value = data
}
const setActiveProject = (project) => {
    projectStore.setActiveProject(project)
}
const parseRouteValue = (value) => {
    return value ? Array.isArray(value) ? value?.map(item => Number(item)) : [Number(value)] : []
}
const setQueryParameters = () => {
    projectStore.setQueryParameters({
        activeApartmentTypes: parseRouteValue(route.query?.type),
        activeApartmentBlocks: parseRouteValue(route.query?.block),
        activeApartmentFloors: parseRouteValue(route.query?.floor),
        activeApartmentCategories: parseRouteValue(route.query?.category),
    })
}
let isPinSectionWider = ref(false)
const setPinSectionHeight = async () => {
    await nextTick()
    let containerBox = document.querySelector('.filter-section-container')?.getBoundingClientRect()
    let alpha = containerBox?.height - window.innerHeight
    isPinSectionWider.value = alpha < 0
}
provide('project', {
    apartmentTypes,
    blocks,
    floors,
    typeTaxonomy,
    setPinSectionHeight,
    parseRouteValue
})

const getData = async () => {
    setQueryParameters()
    await getProjects()
    getTypes()
    await getBlocks()
    getFloors()
    await projectStore.getFlats()
    menuStore.scrollToTopLoco = !menuStore.scrollToTopLoco;
}

onMounted(() => {
    setPinSectionHeight()
    favoriteStore.getFavoriteFlats()
    getData()
})
</script>

<style lang="scss">
.apartment-search {
    display: flex;
    position: relative;
    @media only screen and (max-width: 1023px) {
        display: block;
        padding: 0 22px 0;
    }

    .pin-section {
        position: absolute;
        height: calc(100% - 100px);
        top: -250px;
        pointer-events: none;

        &.wider {
            height: 100%;
        }
    }

    .right-section {
        width: calc(100% - 574px);
        padding: 0 50px 0 80px;
        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            width: calc(100% - 400px);
            padding: 0 30px 0 50px;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1024px) {
            width: calc(100% - 350px);
            padding: 0 20px 0 20px;
        }
        @media only screen and (max-width: 1023px) {
            width: 100%;
            padding: 0;
            margin-top: 21px;
        }

    }


}

</style>

<style lang="scss">
body {
    &.is-dark {
        .el-popper {
            border: transparent;
        }

        .el-popper__arrow {
            &:before {
                background: $primaryNavy !important;
                border-color: $primaryNavy !important;
            }
        }

        .el-select-dropdown__wrap {
            background: $primaryNavy !important;
            border: unset;

            li {
                color: white !important;

            }
        }

        .el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
            background: rgba(24, 34, 54, 0.2);
        }
    }
}

</style>

