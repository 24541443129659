<template>
    <div @click="goByType" class="apartment">
        <div class="content">
            <h2 class="header luxury">{{ item.title }}</h2>
            <span class="description area fira">{{ $fn.tr('Area') }}: {{ item.area }}</span>
            <div class="view-container">
                <span class="description fira">{{ $fn.tr('View') }}:</span>
                <div v-for="view in item.views" class="item">
                    <div class="logo" v-html="view.icon"></div>
                    <span class="description fira">{{ view.title }}</span>
                </div>
            </div>
            <div class="explore-container">
                <svg width="38" height="10" class="icon" viewBox="0 0 38 10" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M31.8652 8.75479C31.5964 9.01868 31.5746 9.47032 31.8166
                           9.76354C32.0585 10.0568 32.4726 10.0805 32.7415 9.81663L37.6484 5L32.7415
                            0.183367C32.4726 -0.0805321 32.0585 -0.0567618 31.8166 0.236459C31.5746
                             0.529679 31.5964 0.981315 31.8652 1.24521L35.0279 4.34961H0.65C0.291015
                              4.34961 0 4.64062 0 4.99961C0 5.35859 0.291015 5.64961
                              0.65 5.64961H35.0287L31.8652 8.75479Z"
                          fill="#940128"></path>
                </svg>
                <span class="title fira">{{ $fn.tr('Explore') }}</span>
            </div>
        </div>
        <picture class="project-item-image">
            <source
                media="(max-width:767px)"
                :srcset="item.mobile1"
            />
            <source
                media="(max-width:1023px)"
                :srcset="item.tablet1"
            />
            <img
                :src="item.desktop1"
            />
        </picture>
    </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import {useProjects} from "../../../../store/pinia/projects";
import {inject} from "vue";
import {useMenuStore} from "../../../../store/pinia/menu";

let projectStore = useProjects()
let menuStore = useMenuStore()
let props = defineProps({
    item: {
        type: Object,
        required: true
    }
})
let router = useRouter()
let route = useRoute()

const {
    setPinSectionHeight
} = inject('project')

const goByType = async () => {
    if (props.item.changeUrlById) {
        projectStore.setQueryParameters({
            activeApartmentTypes: props?.item?.category?.[0],
        })
        await projectStore.getFlats()
        router.push({
            path: route.path,
            query: {
                'type': props.item.category?.[0],
            },
        }).then(() => {
            projectStore.setIsQueryParameters(true)
            setPinSectionHeight()
            setTimeout(() => {
                menuStore.triggerUpdate = !menuStore.triggerUpdate
            }, 100)
        })

        return
    }
    router.push({
        path: route.path + '/apartments',
        query: {
            'type': props.item.category?.[0],
        },
    })
}
</script>

<style lang="scss" scoped>
.apartment {
    $ease: cubic-bezier(.09, .14, .2, 1);
    color: $primaryNavy;
    background: rgba(207, 203, 191, 0.3);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 35px;
    padding-right: 30px;
    margin-top: 21px;
    transition: background .45s $ease;
    height: 341px;
    @media only screen and (max-width: 1650px) and (min-width: 1024px) {
        padding-left: 30px;
        padding-right: 15px;
    }
    @media (max-width: 1023px) {
        height: unset;
        padding: 20px;
        align-items: flex-start;
        flex-direction: column-reverse;
    }

    &:first-child {
        margin-top: 0;
    }

    &:hover {
        background: #CFCBBF;

        .explore-container {
            cursor: pointer;
            margin-top: 54px !important;
            height: 17px !important;
            transition: all .25s $ease !important;

            .icon {
                opacity: 1 !important;
                transform: translateX(0) !important;
            }

            .title {
                opacity: 1 !important;
            }
        }
    }

    .content {
        will-change: transform;

        .header {
            font-weight: 400;
            font-size: 34px;
            text-transform: uppercase;
            @media only screen and (max-width: 1650px) and (min-width: 1024px) {
                font-size: 28px;
            }
            @media (max-width: 767px) {
                font-size: 24px;
            }
            @media (max-width: 1023px) {
                margin-top: 12px;
            }
        }

        .title {
            font-weight: 400;
            font-size: 13px;
            display: block;
        }

        .area {
            margin-top: 8px;
        }

        .description {
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            display: block;
        }

        .view-container {
            display: flex;
            align-items: center;
            margin-top: 8px;

            .item {
                margin-left: 8px;
                display: flex;
                align-items: center;

                .logo {
                    margin-right: 8px;
                }
            }
        }

        .explore-container {
            display: flex;
            align-items: center;
            height: 0;
            margin: 0;
            transition: all .25s .5s $ease;
            @media (max-width: 1023px) {
                display: none;
            }

            .icon {
                margin-right: 15px;
                opacity: 0;
                transform: translateX(-30px);
                transition: all .45s .25s $ease;

            }

            .title {
                font-weight: 700;
                font-size: 13px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                opacity: 0;
                transition: opacity .45s .25s $ease;
                @media (max-width: 1023px) {
                    opacity: 1;
                }
            }
        }
    }

    .project-item-image {
        width: 50%;
        @media (max-width: 1023px) {
            width: 100%;
        }

        img {
            width: 100%;
            object-fit: contain;
            @media (max-width: 1023px) {
                max-height: 280px;
            }

        }
    }
}
</style>
<style lang="scss">
body {
    &.is-dark {
        .apartment {
            color: white;
            background: rgba(26, 40, 67, 0.5);

            &:hover {
                background: rgba(9, 13, 20, 0.1);
            }
        }

        .view-container {
            .logo {
                path {
                    fill: white;
                }
            }
        }
    }
}
</style>
