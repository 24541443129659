<template>
    <div class="search-result-section">
        <h2 class="header-title luxury">{{ activeProject.title }}</h2>
        <div class="sort-container">
            <span class="description">{{ $fn.tr('Available') }} {{ flats.length }} {{ $fn.tr('Apartments') }} </span>
            <div class="sort-list">
                <div class="item">
                    <span class="description">{{ $fn.tr('Currency') }}: </span>
                    <el-select class="currency"
                               @change="changeCurrency"
                               v-model="currency">
                        <el-option
                            v-for="item in currencyOption"
                            :key="item.code"
                            :label="item.code"
                            :value="item.value"
                        />

                    </el-select>
                </div>
                <div class="item" id="sortSelect">
                    <span class="description">{{ $fn.tr('Sort by') }}: </span>
                    <el-select v-model="sortBy"
                               @change="sortFlats">
                        <el-option
                            v-for="item in sortOption"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        />
                    </el-select>
                </div>
            </div>
        </div>
        <ApartmentSearchResultItem v-for="item in sortedFlats"
                                   :apartment="item"
                                   :key="item.id"/>
    </div>
</template>

<script setup>
import {
    ElSelect, ElOption,
} from 'element-plus'
import ApartmentSearchResultItem from './ApartmentSearchResultItem'
import {useProjects} from "../../../../store/pinia/projects";
import {computed, onMounted, ref, nextTick} from 'vue'
import {useMenuStore} from "../../../../store/pinia/menu";
import fn from "@/helpers/func"
import _sortBy from 'lodash/sortBy'

const menuStore = useMenuStore()

let sortOption = [
    {
        label: fn.tr('Price: Low to High'),
        orderBy: 'asc',
        value: '1',
        orderField: 'price'
    },
    {
        label: fn.tr('Price: High to Low'),
        orderBy: 'desc',
        value: '2',
        orderField: 'price'
    },
    {
        label: fn.tr('Area: Low to High'),
        orderBy: 'asc',
        value: '3',
        orderField: 'area'
    },
    {
        label: fn.tr('Area: High to Low'),
        orderBy: 'desc',
        value: '4',
        orderField: 'area'
    },
]
let sortBy = ref(sortOption[0])
let usd = computed(() => menuStore?.indx.usd?.usd)

let currencyOption = [
    {
        code: 'GEL',
        value: 1
    },
    {
        code: 'USD',
        value: usd.value
    },
]
let currency = ref(usd.value)
let projectStore = useProjects()
let flats = computed(() => projectStore.flats)
let activeProject = computed(() => projectStore.activeProject)

const sortFlats = (value) => {
    let activeSort = sortOption.find(option => option.value === value)
    projectStore.setQueryParameters({
        orderBy: activeSort.orderBy,
        orderField: activeSort.orderField,
    })
    projectStore.getFlats()
    setInputWidth()
}
const setInputWidth = (value) => {
    let input = document.querySelector('#sortSelect input')
    input.style.width = sortOption.find(item => item.value === value)?.label?.length + 2 + "ch";
}
const changeCurrency = () => {
    let activeCurrency = currencyOption?.find(item => item.value === currency.value)

    projectStore.updateFlatsPrice(activeCurrency)
}

function compare(a, b) {
    if (a.taxonomies.length > 0) {
        return -1;
    }

    return 0;
}

let sortedFlats = computed(() => {
    return flats.value?.sort(compare)
})
onMounted(async () => {
    projectStore.updateFlatsPrice(currencyOption[1])
    await nextTick()
    setTimeout(() => {
        menuStore.triggerUpdate = !menuStore.triggerUpdate
    }, 2000);
    setInputWidth(sortOption[2])
})
</script>

<style lang="scss" scoped>
.search-result-section {
    .header-title {
        font-style: normal;
        font-weight: 400;
        font-size: 55px;
        text-transform: uppercase;
        margin-top: 10px;
        @media only screen and (max-width: 1650px) and (min-width: 1024px) {
            font-size: 34px;
        }
        @media only screen and (max-width: 1023px) {
            font-size: 34px;
        }
        @media only screen and (max-width: 767px) {
            font-size: 24px;
        }
    }

    .sort-container:deep {
        margin-top: 24px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 23px 34px;
        background: $primaryNavy;
        @media only screen and (max-width: 1439px) and (min-width: 1024px) {
            padding: 20px 10px;
        }
        @media only screen and (max-width: 1023px) {
            padding: 15px 12px;
        }

        .el-select {
            &.currency {
                width: 51px;
            }


            .el-input__wrapper {
                background: transparent;
                border: unset;
                box-shadow: unset !important;
                padding: 0;
                outline: none;
                -webkit-user-select: none;
            }

            .el-input__inner {
                font-family: 'FiraGO';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: white;
                text-transform: uppercase;
                @media only screen and (max-width: 1439px) and (min-width: 1024px) {
                    font-size: 14px;
                }
            }

            .el-icon {
                margin-right: 0;

                svg {
                    color: white;
                }
            }
        }

        .description {
            font-family: FiraGO;
            font-weight: 400;
            font-size: 16px;
            text-transform: uppercase;
            @media only screen and (max-width: 1439px) and (min-width: 1024px) {
                font-size: 14px;
            }
        }

        .sort-list {
            display: flex;
            align-items: center;
            @media only screen and (max-width: 1023px) {
                display: none;
            }

            .item {
                &:last-child {
                    margin-left: 30px;
                }
            }
        }
    }
}
</style>

<style lang="scss">
.el-select-dropdown__item {
    span {
        font-family: 'FiraGO';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        text-transform: uppercase;
    }
}

body {
    &.is-dark {
        .search-result-section {
            color: white;
        }
    }
}
</style>
